@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');


html {
  font-family: 'Open Sans', sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
  .primary-box {
    @apply p-4 m-4 shadow-md rounded-lg bg-white
  }
}


input[type="checkbox"]:checked ~ .checkIcon {
  @apply opacity-100;
}